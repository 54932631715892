<template>
  <el-config-provider namespace="ep">
    <el-container>
      <el-header
        ><el-affix :offset="0"><Header /></el-affix
      ></el-header>
      <el-main><Main /></el-main>
      <el-footer><Footer /></el-footer>
    </el-container>
  </el-config-provider>
</template>

<style type="sass">
#app {
  text-align: center;
  color: var(--ep-text-color-primary);
}

.main-container {
  /* height: calc(100vh - var(--ep-menu-item-height) - 3px); */
}

.ep-main {
  /* background-color: bisque; */
}

.ep-header {
  padding: 0 1px;
  /* .ep-affix {
    background-image: radial-gradient(transparent 1px, var(--bg-color) 1px);
  } */
}
.ep-footer {
  padding: 6px 16px;
  background-color: aquamarine;
}

/* 解决内部滚动条 */
/* .el-main {
  overflow:visible;
} */
</style>
