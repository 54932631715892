import { createApp } from "vue";
import App from "./App.vue";
//import ElementPlus from "element-plus";
// import all element css, uncommented next line
//import "element-plus/dist/index.css";

// or use cdn, uncomment cdn link in `index.html`

import "~/styles/index.scss";
import "uno.css";

// If you want to use ElMessage, import it.
import "element-plus/theme-chalk/src/message.scss";

//createApp(App).mount('#app')
const app = createApp(App);
//app.use(ElementPlus, { size: "small" });
app.mount("#app");

